.section {
  padding: 60px 0 120px;

  .container {
    max-width: $wide-content-width;
    padding: 0 32px;
    margin: 0 auto;
  }

  .scrollingContainer {
    @include flex(row);

    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    transition: all 0.3s ease-in-out;
  }

  .scrollingElement {
    @include flex(row, space-between, center);

    animation: image-scroll 32s linear infinite;
  }
}
