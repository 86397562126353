.homepage {
  &.secondSectionView {
    padding: 120px 0 60px;

    .title {
      padding: 0 16px;
    }

    [data-title] {
      @include fontSize(38px, 120%);

      color: $gray900;
      font-weight: 600;

      @include below-tablet {
        @include fontSize(24px, 150%);
      }
    }

    [data-description] {
      @include fontSize(20px, 150%);

      color: $black-alpha;

      @include below-tablet {
        @include fontSize(16px, 150%);
      }
    }

    .scrollingElement {
      gap: 16px;
      padding-left: 16px;
      animation: image-scroll-reverse 32s linear infinite;
    }

    .item {
      @include flex(column, center, center);

      padding: 48px 32px;
      flex: 0 0 332px;
      border-radius: 999px;
      box-sizing: border-box;

      .itemTitle {
        @include fontSize(56px, 67.2px);

        margin: 0;
      }

      .itemDescription {
        margin: 0;
      }

      &-1 {
        background-color: $gray100;
        color: $gray600;
      }

      &-2 {
        background-color: $gray900;
        color: $gray300;
      }

      &-3 {
        background-color: $gray50;
        color: $gray900;
      }

      &-4 {
        background-color: $yellow200;
        color: $yellow800;
      }

      &-5 {
        background-color: $yellow100;
        color: $gray900;
      }
    }

    @include phone {
      padding: 60px 0 30px;
    }
  }
}
